<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createTouristTrip"
        v-if="hasPermission('create_tourist_trip')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>
    <b-card>
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Tourist Place"
              label-for="touristattraction"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <v-select
                id="touristattraction"
                v-model="selectedTouristAttraction"
                :options="touristattractions"
                placeholder="Select Tourist Attraction"
                label="name"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="touristtrips"
        details-td-class="m-0 p-0"
        small
        :per-page="perPage"
      >
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>
        <template #cell(name)="row">
          {{ truncate(row.item.name, 20) }}
        </template>
        <template #cell(departure)="row">
          {{ truncate(row.item.departure, 20) }}
        </template>
        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(tourist_attraction)="row">
          {{ truncate(row.item.tourist_attraction_name, 20) }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editTouristTrip(row.item)"
            v-if="hasPermission('update_tourist_trip')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeTouristTrip(row.item)"
            v-if="hasPermission('delete_tourist_trip')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
    </b-card>
    <TouristTripCreateModal
      @modalClosed="onModalClosed"
      :key="`create-${touristtripCreateModalCount}`"
    />
    <TouristTripEditModal
      :touristtrip="touristtrip"
      @modalClosed="onModalClosed"
      :key="`edit-${touristtripEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import TouristTripCreateModal from "@/components/admin/tourist_trip/TouristTripCreateModal.vue";
import TouristTripEditModal from "@/components/admin/tourist_trip/TouristTripEditModal.vue";

export default {
  components: {
    TouristTripCreateModal,
    TouristTripEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "tourist_attraction", label: "Tourist Attraction" },
        { key: "departure", label: "Departure" },
        { key: "charges", label: "Charges" },
        { key: "days", label: "Days" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Updated At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      touristattractions: [],
      selectedTouristAttraction: null,
      touristtripCreateModalCount: 0,
      touristtripEditModalCount: 0,
      searchTypes: [{ value: 1, name: "Tourist Attraction" }],
      searchType: null,
      id: "",
      touristtrips: [],
      touristtrip: null, // Initialize the missing property here
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
    await this.fetchTouristAttractions();
  },
  methods: {
    ...mapActions({
      getTouristtrips: "appData/getTouristtrip",
      deleteTouristTrip: "appData/deleteTouristtrip",
      getTouristAttractionsPublic: "appData/getTouristattractionPublic",
    }),
    async search() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchTouristAttractions() {
      try {
        const res = await this.getTouristAttractionsPublic();
        this.touristattractions = res.data;
      } catch (error) {
        console.error("Error fetching tourist attraction:", error);
      }
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
          touristattraction: this.selectedTouristAttraction
            ? this.selectedTouristAttraction.id
            : null,
        };
        const res = await this.getTouristtrips(tempParams);
        this.touristtrips = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createTouristTrip() {
      this.touristtripCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("tourist-trip-create-modal");
      });
    },
    editTouristTrip(touristtrip) {
      this.touristtrip = touristtrip;
      this.touristtripEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("tourist-trip-edit-modal");
      });
    },
    async removeTouristTrip(touristtrip) {
      this.touristtrip = touristtrip;
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteTouristTrip({
              pk: this.touristtrip.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Tourist Trip deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            console.error("Error deleting tourist-trip:", error);
          }
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
