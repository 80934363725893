<template>
  <b-modal
    id="tourist-trip-create-modal"
    title="Tourist Trip Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Trip Create</h2>
    </template>
    <validation-observer ref="touristTripCreateFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="tourist_attraction">
          <template #label>
            Tourist Attraction <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tourist Attraction"
            rules="required"
          >
            <v-select
              v-model="touristAttraction"
              placeholder="Select Tourist Attraction"
              :options="touristAttractions"
              label="name"
              value="id"
              clearable
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="departure">
          <template #label>
            Departure City<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="departure"
            v-model="departure"
            placeholder="Departure City"
            required
            @keydown="checkMaxLength($event, 'departure', 200)"
          />
        </b-form-group>

        <b-form-group label-for="charges">
          <template #label>
            Charges<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="charges"
            v-model="charges"
            placeholder="Charges"
            type="number"
            required
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="days">
          <template #label> Days<span class="text-danger">*</span> </template>
          <b-form-input
            id="days"
            v-model="days"
            placeholder="Days"
            type="number"
            required
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Tour Plans </template>
          <quill-editor v-model="tourPlans" />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      required,
      touristAttraction: null,
      touristAttractions: [],
      departure: "",
      charges: null,
      days: null,
      tourPlans: "",
      status: "",
      regex: /^[a-zA-Z ]*$/,

      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
    };
  },
  methods: {
    ...mapActions({
      createTouristTrip: "appData/createTouristtrip",
      getTouristAttractionsPublic: "appData/getTouristattractionPublic",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success =
        await this.$refs.touristTripCreateFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const formData = new FormData();
        formData.append("tourist_attraction", this.touristAttraction.id);
        formData.append("departure", this.departure);
        formData.append("days", this.days);
        formData.append("charges", this.charges);
        formData.append("tour_plans", this.tourPlans);
        formData.append("status", this.status);
        formData.append("created_by", this.getLoggedInUser.id);
        formData.append("updated_by", this.getLoggedInUser.id);
        const res = await this.createTouristTrip(formData);
        if (res.status === 200) {
          this.$swal({
            title: "Tourist Trip created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("tourist-trip-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.touristAttraction = null;
      this.departure = "";
      this.charges = null;
      this.days = null;
      this.tourPlans = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },

  async mounted() {
    const res_touristAttractions = await this.getTouristAttractionsPublic();
    this.touristAttractions = res_touristAttractions.data;
  },
};
</script>

<style></style>
