<template>
  <b-modal
    id="tourist-trip-edit-modal"
    title="Tourist Trip Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Tourist Trip Edit</h2>
    </template>
    <validation-observer ref="touristTripEditFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="tourist_attraction">
          <template #label>
            Tourist Attraction <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tourist Attraction"
            rules="required"
          >
            <v-select
              label="name"
              v-model="touristAttraction"
              placeholder="Select Tourist Attraction"
              :options="touristAttractions"
              :reduce="(option) => option.id"
              autoscroll
              :clearable="true"
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="departure">
          <template #label>
            Departure City<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="departure"
            v-model="departure"
            required
            placeholder="Departure City"
            @keydown="checkMaxLength($event, 'departure', 200)"
          />
        </b-form-group>

        <b-form-group label-for="charges">
          <template #label>
            Charges<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="charges"
            v-model="charges"
            placeholder="Charges"
            type="number"
            required
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="days">
          <template #label> Days<span class="text-danger">*</span> </template>
          <b-form-input
            id="days"
            v-model="days"
            placeholder="Days"
            type="number"
            required
            min="1"
            step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="tourPlans">
          <template #label> Tour Plans </template>
          <quill-editor id="tourPlans" v-model="tourPlans" />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
  },
  props: {
    touristtrip: Object,
  },
  data() {
    return {
      touristAttraction: "",
      departure: "",
      touristAttraction: null,
      touristAttractions: [],
      charges: "",
      days: "",
      tourPlans: "",
      status: "",
      regex: /^[a-zA-Z ]*$/,

      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
    };
  },
  async mounted() {
    const res_touristAttractions = await this.getTouristAttractionsPublic();
    this.touristAttractions = res_touristAttractions.data;

    if (this.touristtrip) {
      this.touristAttraction = this.touristtrip.tourist_attraction;
      this.departure = this.touristtrip.departure;
      this.charges = this.touristtrip.charges;
      this.days = this.touristtrip.days;
      this.tourPlans = this.touristtrip.tour_plans;
      this.status = this.touristtrip.status;
    }
  },
  methods: {
    ...mapActions({
      updateTouristTrip: "appData/updateTouristtrip",
      getTouristAttractionsPublic: "appData/getTouristattractionPublic",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.touristTripEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    async submit() {
      try {
        const res = await this.updateTouristTrip({
          payload: {
            tourist_attraction: this.touristAttraction,
            departure: this.departure,
            charges: this.charges,
            days: this.days,
            tour_plans: this.tourPlans,
            status: this.status,
            updated_by: this.getLoggedInUser.id,
          },
          pk: this.touristtrip.id,
        });

        if (res.status === 200) {
          this.$swal({
            title: "Tourist trip updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("tourist-trip-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.touristAttraction = "";
      this.departure = "";
      this.charges = "";
      this.days = "";
      this.tourPlans = "";
      this.status = "";
    },
    displayError(error) {
      console.error(error);
      this.$swal({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },
};
</script>

<style></style>
